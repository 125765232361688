'use strict'

$('.js-tab__item a').each(
  ()->
    $(this).off('click.smoothScroll')
)

$('.js-tab__item a').on('click',
  (e)->
    e.preventDefault()

    $tab = $(this).closest('.js-tab')

    $tab.find('.js-tab__item').removeClass('is-active')
    $(this).closest('.js-tab__item').addClass('is-active')

    $tab.siblings('.js-tab__contents__wrapper').find('.js-tab__contents').removeClass('is-active')
    $($(this).attr('href')).addClass('is-active')
)